import { Box, Link, Tile, Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage } from "react-intl"
import { TtcPaths } from "#/utils/Paths"

export function MemberOnlySpaceNotice() {
  const theme = useTheme()
  return (
    <>
      <Box display="flex" justifyContent="center" marginTop={theme.tokens.spacing.xxxl}>
        <Box maxWidth={"37.5rem"}>
          <Tile color="default">
            <Typography variant="h3" color={(theme) => theme.palette.text.secondary}>
              <FormattedMessage defaultMessage={`You've reached a member-only space`} />
            </Typography>
            <Box marginTop={theme.tokens.spacing.xs} marginBottom={theme.tokens.spacing.lg}>
              <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
                <FormattedMessage
                  defaultMessage={`For privacy and legal reasons, this space is only available to the primary Carrot member. For access, please ask the member to sign in to their account.`}
                />
              </Typography>
            </Box>
            <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
              <FormattedMessage
                defaultMessage="Questions? <link>Contact us.</link>"
                values={{
                  link: (linkContent) => (
                    <Link color="primary" component={Link} href={`${TtcPaths.TTC_CARE_NAV}`}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Typography>
          </Tile>
        </Box>
      </Box>
    </>
  )
}
